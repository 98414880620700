import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { Notification } from '../../../layout/common/notifications/notifications.types';
import { NotificationsService } from '../../../layout/common/notifications/notifications.service';
import { Subject, takeUntil } from 'rxjs';
import { SnackbarService } from '../../../shared/services/snackbar/snackbar.service';
import { ModalService } from '../../../shared/services/modal.service';
import { fuseAnimations } from '../../../../@fuse/animations';
import { AuthViewDirective } from '../../../shared/directives/auth-view.directive';

@Component({
  selector: 'app-help-view',
  standalone: true,
  imports: [
    MatButton,
    MatIcon,
    MatInput,
    NgIf,
    ReactiveFormsModule,
    TranslocoPipe,
    DatePipe,
    NgClass,
    MatIconButton,
    AuthViewDirective,
  ],
  templateUrl: './help-view.component.html',
  styles: ``,
  animations: [fuseAnimations],
})
export class HelpViewComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @Input() help: Notification;
  helpForm: FormGroup;
  notResolveControl: boolean = false;
  @ViewChild('title') titleElement!: ElementRef;

  constructor(private fb: FormBuilder,
              private modalService: ModalService, private notificationsService: NotificationsService, private snackbarService: SnackbarService) {

  }

  ngOnInit(): void {
    this.helpForm = this.fb.group({
      title: ['', Validators.required],
      message: ['', Validators.required],
    });
  }
  onNotResolveClick() {
    this.notResolveControl = true;
    setTimeout(() => {
      if (this.titleElement) {
        this.titleElement.nativeElement.focus();
      }
    }, 0);
  }
  resolve() {
    this.notificationsService.patch(this.help.id, null, 'resolve/').pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: (response) => {
        this.snackbarService.openSnackBar(
          { title: 'success', message: 'theProblemWasSuccessfullySolved' },
          'success',
        );
        this.modalService.closeModal();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  sendNotResolve() {
    const formData = new FormData();
    formData.append('title', this.helpForm.get('title').value);
    formData.append('message', this.helpForm.get('message').value);
    this.notificationsService.patch(this.help.id, formData, 'not-resolve/').pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: (response) => {
        this.snackbarService.openSnackBar(
          { title: 'success', message: 'requestForAssistanceSuccessfullyAnswered' },
          'success',
        );
        this.modalService.closeModal();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
