import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { HttpClient } from '@angular/common/http';
import { endpointConfig } from '../config/endpoint.config';
import { SnackbarService } from './snackbar/snackbar.service';
import { ProductModel } from '../model/product.model';
import { BehaviorSubject, map, take } from 'rxjs';
import { STATUS } from '../model/status.enum';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends HttpBaseService<ProductModel> {
  public selectedProduct$: BehaviorSubject<ProductModel> = new BehaviorSubject<ProductModel>(null);
  public selectedRoute$: BehaviorSubject<ProductModel> = new BehaviorSubject<ProductModel>(null);
  public isRoute$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public products$: BehaviorSubject<ProductModel[]> = new BehaviorSubject<ProductModel[]>(null);
  public onSaleControl$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(http: HttpClient, _snackBarService: SnackbarService) {
    super(http, endpointConfig.product, _snackBarService);

    this.products$.pipe(
      map(products => {
        const result = products?.every(product => product?.status === STATUS.WAITING) ?? false;
        return { products, result };
      })
    ).subscribe(({ products, result }) => {
      if (products && products.length > 0) {
        this.selectedProduct$.next(products[0]);
      }
      this.onSaleControl$.next(result);
    });


  }
  getProduct(isRoute:boolean,id: number): void {
    this.get(`product-view/${isRoute ? 'route' : 'termin'}/${id}`)
      .pipe(take(1))
      .subscribe(value => {
        this.selectedProduct$.next(new ProductModel(value.data));
        if (isRoute) {
          this.selectedRoute$.next(new ProductModel(value.data));
        }else{
          this.products$.next([new ProductModel(value.data)])
        }
      });
  }


  setSelectedProduct(product: ProductModel) {
    if (product.type === 'ROUTE') {
      this.isRoute$.next(true);
    } else {
      this.isRoute$.next(false);
    }
    this.selectedProduct$.next(product);
  }
}