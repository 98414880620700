import {Injectable} from "@angular/core";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {BaseModalComponent} from "../components/base-modal/base-modal.component";

@Injectable({
    providedIn: "root",
})
export class ModalService {
    constructor(private dialog: MatDialog) {
    }

    public title: string;

    openModalWindow(
        component: any,
        title?: string,
        data?: any,
        customConfig?: MatDialogConfig,
    ) {
        this.title = title;
        const config: MatDialogConfig = {
            height: "auto",
            enterAnimationDuration: 200,
            backdropClass: "backdrop-blur-sm",
            panelClass: "full-width-dialog",
            disableClose: true,
            data: {
                width: customConfig?.width ? customConfig.width : null,
                component: component,
                title: title,
                subData: data ? data : null,
            },
        };

        const dialogRef = this.dialog.open(BaseModalComponent, config);
        return dialogRef.afterClosed()
    }

    closeModal(component?: any) {
        const openDialogs = this.dialog.openDialogs
        const baseModalDialogRef = openDialogs.find(dialog => dialog.componentInstance instanceof (component || BaseModalComponent));
        if (baseModalDialogRef) {
            baseModalDialogRef.close();
        }
    }
}
