import {AsyncPipe, NgClass, NgFor, NgIf, TitleCasePipe} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectChange, MatSelectModule} from '@angular/material/select';
import {ROLES} from '../../../../shared/model/user.roles.enum';
import {TranslocoPipe} from '@ngneat/transloco';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {TablePagination} from '../../../../shared/model/paginate.type';
import {UserService} from '../../../../core/user/user.service';
import {BehaviorSubject, debounceTime, Subject, takeUntil} from 'rxjs';
import {UserModel} from '../../../../shared/model/user.model';
import {GenericResponseModel} from '../../../../shared/model/generic.model';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {Confirmable} from '../../../../shared/decorators/confirmable.decorator';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {AddUserComponent} from "../add-user/add-user.component";
import {ModalService} from "../../../../shared/services/modal.service";
import {SettingsAccountComponent} from "../../../user/profile-settings/account/account.component";

@Component({
  selector: 'app-users-authorization',
  templateUrl: './user-authorization.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatFormFieldModule, MatIconModule, MatInputModule,
    MatButtonModule, NgFor, NgIf, MatSelectModule, MatOptionModule, TitleCasePipe, TranslocoPipe, MatPaginator, AsyncPipe,
    ReactiveFormsModule, FormsModule, NgClass],
})
export class UsersAuthorization implements OnInit {
  eRoles = Object.values(ROLES);
  roles: any[] = [];
  pagination: TablePagination;
  users$: BehaviorSubject<UserModel[]> = new BehaviorSubject<UserModel[]>(null);
  search: UntypedFormControl = new UntypedFormControl(null);
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(private modalService:ModalService,private userService: UserService, private cdr: ChangeDetectorRef, public confirmationService: FuseConfirmationService) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loadData();
    this.eRoles.map(value => {
      const item = {
        label: value,
        value: value,
        description: null,//TODO afterTest
      };
      this.roles.push(item);
    });
    this.search.valueChanges.pipe(takeUntil(this._unsubscribeAll), debounceTime(400)).subscribe(value => {
      this.pagination.pageIndex = 0;
      this.loadData(value);
    });
  }

  pageChanged(event: PageEvent): void {
    this.pagination.pageIndex = event.pageIndex;
    this.pagination.pageSize = event.pageSize;
    this.loadData(this.search.value);
  }

  loadData(query?: string) {
    this.userService.getPaginate<GenericResponseModel<UserModel>>('get-all', {
      ...(query ? { filter: query } : { filter: '' }),
      page: this.pagination?.pageIndex || 0,
      size: this.pagination?.pageSize || 10,
    }).subscribe(value => {
      this.users$.next(value.data.content.map(v => new UserModel(v)));
      this.cdr.markForCheck();
      this.pagination = {
        length: value.data.totalElements,
        pageIndex: value.data.pageable.pageNumber,
        pageSize: value.data.pageable.pageSize,
      };
    });
  }

  @Confirmable({
    title: 'confirmation',
    message: 'doYouWantToUpdateYourPersonalRole',
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation-circle',
      color: 'warn',
    },
  })
  updateRole(user: UserModel, event: MatSelectChange) {
    this.userService.patch(user.id, [event.value]).pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
      this.loadData(this.search.value);
    });
  }

  @Confirmable({
    title: 'confirmation',
    message: 'areYouSureYouWantToDeleteTheUser',
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation-circle',
      color: 'warn',
    },
  })
  deleteUser(user:UserModel){
    this.userService.delete(user.id).pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
      this.loadData(this.search.value);
    });
  }

  addUser() {
    this.modalService.openModalWindow(AddUserComponent, 'addUser', {
    }, { width: '1400px' }).pipe(takeUntil(this._unsubscribeAll)).subscribe((val) => this.loadData());
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }


  updateUser(member: UserModel) {
    this.modalService.openModalWindow(SettingsAccountComponent, 'editUser', {
      selectedUser: member,
    }, {width: '1400px'}).pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.loadData()
    });
  }
}
