import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  changes = new Subject<void>();

  constructor(private translocoService: TranslocoService) {
    super();
    this.setTranslations();

    this.translocoService.langChanges$.subscribe(() => {
      setTimeout(() => {
        this.setTranslations();
        this.changes.next()
      }, 100);
    });
  }

  setTranslations() {
    this.itemsPerPageLabel = this.translocoService.translate('itemsPerPage');
    this.nextPageLabel = this.translocoService.translate('nextPage');
    this.previousPageLabel = this.translocoService.translate('previousPage');
    this.firstPageLabel = this.translocoService.translate('firstPage');
    this.lastPageLabel = this.translocoService.translate('lastPage');
  }
}