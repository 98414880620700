import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, takeUntil} from "rxjs";
import {DatePipe} from "@angular/common";
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatTableDataSource
} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort, MatSortHeader} from "@angular/material/sort";
import {TranslocoPipe} from "@ngneat/transloco";
import {MatIcon} from "@angular/material/icon";
import {BaseTableComponent} from "../../../../shared/components/base-table/base-table.component";
import {MatIconButton} from "@angular/material/button";
import {NotificationsService} from "../../../../layout/common/notifications/notifications.service";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {Confirmable} from "../../../../shared/decorators/confirmable.decorator";
import {SnackbarService} from "../../../../shared/services/snackbar/snackbar.service";

@Component({
    selector: 'app-notifications-list',
    standalone: true,
    imports: [
        DatePipe,
        MatCell,
        MatCellDef,
        MatColumnDef,
        MatHeaderCell,
        MatHeaderRow,
        MatHeaderRowDef,
        MatPaginator,
        MatRow,
        MatRowDef,
        MatSort,
        MatSortHeader,
        MatTable,
        TranslocoPipe,
        MatHeaderCellDef,
        MatIcon,
        MatIconButton
    ],
    templateUrl: './notifications-list.component.html',
})
export class NotificationsListComponent extends BaseTableComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() refreshNotificationsList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public dataSource: MatTableDataSource<Notification> = new MatTableDataSource<Notification>();
    public displayedColumns: string[] = ['title', 'description', 'companyName', 'created', 'actions'];

    constructor(private notificationsService: NotificationsService, private confirmationService: FuseConfirmationService, private snackbarService: SnackbarService) {
        super();
    }

    ngOnInit() {
        this.refreshNotificationsList$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            if (value) {
                this.loadData();
                this.refreshNotificationsList$.next(false);
            }
        });
    }

    ngAfterViewInit() {
        this.pagination?.pageIndex ? this.pagination.pageIndex = 0 : ' ';
        this.dataSource.sort = this._sort;
        this._sort.active = 'created';
        this._sort.direction = 'asc';
        this._sort.sortChange.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: () => {
                this.loadData();
            },
        });
    }

    loadData() {
        this.notificationsService.getPaginate<Notification>('get-all?', {
            ...(this._sort?.active ? { sort: this._sort?.active } : {}),
            ...(this._sort?.direction ? { sortType: this._sort?.direction } : {}),
            page: this.pagination?.pageIndex || 0,
            size: this._paginator?.pageSize || 20
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (response) => {
                    this.dataSource.data = response?.data?.content;
                    this.cdr.markForCheck();
                    this.pagination = {
                        length: response?.data?.totalElements,
                        pageIndex: response?.data?.pageable?.pageNumber,
                        pageSize: response?.data?.pageable?.pageSize,
                    };
                },
            });
    }

    @Confirmable({
        title: 'confirmation',
        message: 'areYouSureYouWantToDeleteTheNotification',
        icon: {
            show: true,
            name: 'heroicons_outline:exclamation-circle',
            color: 'warn',
        },
    })
    deleteNotification(element) {
        console.log(element)
        const notificationId = element.id;
        this.notificationsService.delete<Notification>(notificationId, 'delete/')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (response) => {
                    if (response.success) {
                        this.loadData();
                        this.snackbarService.openSnackBar({
                            title: 'success',
                            message: 'notificationDeleteSuccessfully'
                        }, 'success');
                    }
                },
                error: (err) => {
                    console.error(err);
                }
            });
    }

    pageChanged(event: PageEvent): void {
        this.pagination.pageIndex = event.pageIndex;
        this.pagination.pageSize = event.pageSize;
        this.loadData();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }
}