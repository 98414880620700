import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { concatMap, filter, from, Subject, takeUntil, tap } from 'rxjs';
import { TranslocoPipe } from '@ngneat/transloco';
import { FirebaseService } from '../../../shared/services/firebase.service';
import { UserService } from '../../../core/user/user.service';
import { environment } from '../../../../environments/environment';
import { UserModel } from '../../../shared/model/user.model';
import { fuseAnimations } from '../../../../@fuse/animations';
import { ModalService } from '../../../shared/services/modal.service';
import { StatusChangeComponent } from '../../../shared/components/status-change/status-change.component';
import { STATUS } from '../../../shared/model/status.enum';
import { HelpViewComponent } from '../../../modules/help/help-view/help-view.component';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'notifications',
  standalone: true,
  animations: [fuseAnimations],
  imports: [MatButtonModule, NgIf, MatIconModule, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, DatePipe, TranslocoPipe],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;
  user: UserModel;
  notifications: Notification[] = [];
  unreadCount: number = 0;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _notificationsService: NotificationsService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private firebaseService: FirebaseService,
    private userService: UserService,
    private modalService:ModalService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to notification changes
    // this._notificationsService.notifications$
    //     .pipe(takeUntil(this._unsubscribeAll))
    //     .subscribe((notifications: Notification[]) =>
    //     {
    //         this.notifications= notifications
    //         // Load the notifications
    //         this._calculateUnreadCount();
    //         // Mark for check
    //         this._changeDetectorRef.markForCheck();
    //     });
    this.userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
      if (value) {
        this.notifications = [];
        this.user = new UserModel(value);
        this.firebaseService.receiveUserMessages(value.id, (data: Notification) => {
          if (data.image) {
            data.image = environment.apiUrl + '/' + data.image['path'] + '?token=' + localStorage.getItem('accessToken');
          }
          this.notifications.unshift(data);
          // Calculate the unread count
          this._calculateUnreadCount();
          // Mark for check
          this._changeDetectorRef.markForCheck();
        });
      }
    });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  openNotification(notification: Notification) {
    if (String(notification.type) === 'HELP') {
      this.openHelpView(notification);
    }
  }

  openHelpView(notification: Notification) {
    this.modalService.openModalWindow(HelpViewComponent, 'helpDetails',
      {
        help: notification,
      }, { width: notification.image? null: '1024px' }).pipe(takeUntil(this._unsubscribeAll)).subscribe(
      () => {
        this.closePanel()
      })
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the notifications panel
   */
  openPanel(): void {
    // Return if the notifications panel or its origin is not defined
    if (!this._notificationsPanel || !this._notificationsOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
  }

  /**
   * Close the notifications panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(): void {
    // Mark all as read
    from(this.notifications).pipe(
      filter(value => !value.read),
      concatMap(value =>
        this._notificationsService.patch(null, null, `read/${value.id}/${this.user.id}?read=${true}`).pipe(
          tap(() => {
            value.read = true;
            this._calculateUnreadCount();
          }),
        ),
      ),
    ).subscribe({
      next: () => {
        this._changeDetectorRef.markForCheck();
      },
      error: (err) => {
        console.error('Error marking notifications as read', err);
      },
    });
  }

  /**
   * Toggle read status of the given notification
   */
  toggleRead(notification: Notification): void {
    // Toggle the read status
    notification.read = !notification.read;

    // Update the notification
    // this._notificationsService.update(notification.id, notification).subscribe();
    this._notificationsService.patch(null, null, `read/${notification.id}/${this.user.id}?read=${notification.read}`).subscribe(value => {
      this._calculateUnreadCount();
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * Delete the given notification
   */
  delete(notification: Notification): void {
    // Delete the notification
    this._notificationsService.delete(null, `delete/${notification.id}/${this.user.id}`).subscribe(value => {
      this.notifications = this.notifications.filter(v => v.id !== notification.id);
      this._notificationsService.notifications = this.notifications;
      this._calculateUnreadCount();
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the overlay
   */
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay.position()
        .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  /**
   * Calculate the unread count
   *
   * @private
   */
  private _calculateUnreadCount(): void {
    let count = 0;

    if (this.notifications && this.notifications.length) {
      count = this.notifications.filter(notification => !notification.read).length;
    }

    this.unreadCount = count;
  }
}
