import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { endpointConfig } from '../config/endpoint.config';
import { TerminModel } from '../model/termin.model';
import { BehaviorSubject, catchError, map, Observable, of, take, throwError } from 'rxjs';
import { GenericResponseModel } from '../model/generic.model';
import { SnackbarService } from './snackbar/snackbar.service';
import { VoiceModel } from '../model/voice.model';
import { ProductModel } from '../model/product.model';
import { ProductService } from './product.service';

@Injectable({
  providedIn: 'root',
})
export class TerminService extends HttpBaseService<TerminModel> {
  public selectedTermin$:BehaviorSubject<TerminModel> = new BehaviorSubject<TerminModel>(null)
  public expandedRoute$:BehaviorSubject<ProductModel[]> = new BehaviorSubject<ProductModel[]>(null)

  constructor(http: HttpClient, _snackBarService: SnackbarService,public productService:ProductService) {
    super(http, endpointConfig.termin, _snackBarService);
  }

  loadRoute(routeId: number,focusTerminId?:number) {
      this.get<ProductModel[]>(`get-by-route/` + routeId)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.expandedRoute$.next(response.data.map(x => new ProductModel(x)));
            this.productService.products$.next(this.expandedRoute$.value)
            if (response.data.length > 0) {
              if(focusTerminId){
                this.loadTermin(focusTerminId)
              }else {
                this.loadTermin(response.data[0].productId);
              }
            }
          },
        });
  }
  loadTermin(productId: number) {
    this.get<GenericResponseModel<TerminModel>>(`${productId}`)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response && response.data) {
            this.selectedTermin$.next(new TerminModel(response.data));
          }
          if(!this.productService.isRoute$.value){
            this.productService.products$.next([this.productService.selectedProduct$.value])
          }
        },
      });
  }

  downloadPdf<T>(body: T, urlSuffix?: string, fileName?: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    // @ts-ignore
    return this.http
      .post<Blob>(this.modelBaseUrl + (urlSuffix ? urlSuffix : ''), body, {
        headers: headers,
        // @ts-ignore
        responseType: 'arraybuffer',
      })
      .pipe(
        map(response => {
          const blob = new Blob([response]);
          const file = new Blob([blob], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank', 'width=1000, height=800');
          const a = document.createElement('a');
          a.href = fileURL;
          a.download = fileName || 'document.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }),
      );
  }

  importExcelTermin<TerminPreviewModel>(type: string, body: TerminPreviewModel, file: File, urlSuffix?: string): Observable<GenericResponseModel<TerminModel[]>> {
    const formData: FormData = new FormData();
    formData.append('type', type);
    formData.append('excelMatch', JSON.stringify(body));
    formData.append('file', file);
    return this.http.post<GenericResponseModel<TerminModel[]>>(this.modelBaseUrl + (urlSuffix ? urlSuffix : ''), formData).pipe(
      catchError(error => {
        if (error && error.error && !error.error.success) {
          this._snackBarService.openSnackBar(
            { title: error.error.title? error.error.title : 'Error', message: error.error.message },
            'warn',
          );
        }
        return throwError(error);
      }),
    );
  }

  uploadVoiceFiles(terminId: number, voices: File[]): Observable<GenericResponseModel<TerminModel>> {
    const formData: FormData = new FormData();
    voices.forEach(file => formData.append('voices', file));
    return this.http.patch<GenericResponseModel<TerminModel>>(`${this.modelBaseUrl}${terminId}`, formData).pipe(
      catchError(error => {
        if (error && error.error && !error.error.success) {
          this._snackBarService.openSnackBar(
            { title: 'Error', message: error.error.message },
            'warn',
          );
        }
        return throwError(error);
      }),
    );
  }

  onVoiceFileSelected(event: any, termin: TerminModel | null): Observable<TerminModel | null> {
    const filesArray: File[] = Array.from(event.target.files);
    const allowedExtensions = ['.mp3', '.m4a', '.mp4', '.wav', '.wma', '.aac', '.flac', '.ogg', '.aiff', '.alac', '.opus'];
    const allFilesValid = filesArray.every(file => allowedExtensions.includes(`.${file.name.split('.').pop().toLowerCase()}`));

    const duplicateFiles = termin?.voices && Array.isArray(termin.voices) ?
      filesArray.some(file => termin.voices.some(voice => voice.name === file.name)) : false;

    if (!filesArray.length) return of(termin);

    if (allFilesValid && !duplicateFiles) {
      if (termin) {
        termin.voices = Array.isArray(termin.voices) ? termin.voices : [];
        filesArray.forEach(file => {
          termin.voices.push(new VoiceModel({ name: file.name }));
        });

        return this.uploadVoiceFiles(termin.id, filesArray).pipe(
          map(value => {
            this._snackBarService.openSnackBar(
              { title: 'Success', message: 'audiFilesUploadedSuccessfully' },
              'success',
            );
            return value.data;
          }),
        );
      }
    } else {
      event.target.value = '';
      this._snackBarService.openSnackBar(
        {
          title: 'Error',
          message: duplicateFiles ?
            'duplicateFileNamesFoundPleaseSelectFilesWithUniqueNames' :
            'invalidFileTypesPleaseSelectValidAudioFiles',
        },
        'warn',
      );
    }

    return of(termin);
  }

  uploadProductToRequestFile(
    requestId: number,
    excelMatch: any,
    file: File,
  ): Observable<GenericResponseModel<TerminModel[]>> {
    const formData: FormData = new FormData();
    formData.append('excelMatch', JSON.stringify(excelMatch));
    formData.append('file', file);

    return this.http.post<GenericResponseModel<TerminModel[]>>(
      `${endpointConfig.request}upload-products/${requestId}`,
      formData,
    ).pipe(
      catchError(error => {
        if (error && error.error && !error.error.success) {
          this._snackBarService.openSnackBar(
            { title: 'Error', message: error.error.message },
            'warn',
          );
        }
        return throwError(error);
      }),
    );
  }
}
