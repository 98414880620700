<div class="flex flex-col items-center pr-3 overflow-y-auto max-h-[90vh]">
  <div class="w-full">
    <div class="relative z-10 flex flex-col w-full">
      <div class="flex flex-col w-full gap-3 p-5">
        <div *ngIf="help.image" class="flex flex-col items-center gap-1">
          <img class="max-w-3xl rounded-md" [src]="help.image" alt="helpImage">
        </div>
        <!-- Date -->
        <div class="flex flex-row justify-start gap-4">
          <label class="text-gray-700 font-semibold">{{ 'date' | transloco }}:</label>
          <div>
            {{ help['created'].time | date:'MMM dd, h:mm a' }}
          </div>
        </div>
        <!-- Company -->
        <div class="flex flex-row justify-start gap-4" [authView]="['ROLE_QC']" [hide]="true">
          <label class="text-gray-700 font-semibold">{{ 'company' | transloco }}:</label>
          <div>
            {{ help.createdByCompany }}
          </div>
        </div>
        <!-- Title -->
        <div class="flex flex-row justify-start gap-4">
          <label class="text-gray-700 font-semibold">{{ 'title' | transloco }}:</label>
          <div>
            {{ help.title }}
          </div>
        </div>
        <!-- Message -->
        <div class="flex flex-col gap-1">
          <label class="text-gray-700 font-semibold">{{ 'message' | transloco }}</label>
          <div class="p-3 overflow-y-auto resize rounded-xl bg-white border border-1">
            {{ help.description }}
          </div>
        </div>
        <div class="flex justify-end gap-4" [authView]="['ROLE_QC']" [hide]="true">
          <button mat-flat-button class="bg-red-600 text-white" (click)="onNotResolveClick()">
            {{ 'notResolved' | transloco }}
          </button>
          <button mat-flat-button class="bg-primary text-white" (click)="resolve()">
            {{ 'resolved' | transloco }}
          </button>
        </div>
        <div class="flex flex-col items-center" *ngIf="notResolveControl" @expandCollapse>
          <div class="w-full px-6">
            <div class="relative overflow-visible bg-card rounded-3xl  border border-1 border-red-400 shadow-lg">
              <button (click)="notResolveControl=false"
                      mat-icon-button
                      class="z-50 absolute top-1 right-2 text-gray-700 hover:text-red-600 focus:outline-none">
                <mat-icon>close</mat-icon>
              </button>

              <div class="relative z-10 flex flex-col w-full pt-6">
                <form [formGroup]="helpForm">
                  <div class="flex flex-col w-full gap-6 p-5">
                    <div class="flex flex-col gap-1">
                      <label for="title" class="text-gray-700 font-semibold">{{ 'title' | transloco }}</label>
                      <input type="text" id="title" #title class="bg-white border-2 p-3 rounded-xl"
                             formControlName="title"
                             [placeholder]="'pleaseEnterTheTitle' | transloco">
                    </div>
                    <div class="flex flex-col gap-1">
                      <label for="message"
                             class="text-gray-700 font-semibold">{{ 'message' | transloco }}</label>
                      <textarea matInput id="message"
                                class="p-3 overflow-y-auto resize rounded-xl bg-white border border-1"
                                [placeholder]="'pleaseEnterTheMessage' | transloco" rows="5"
                                formControlName="message"></textarea>
                    </div>
                    <div class="flex justify-end">
                      <button mat-flat-button class="text-white"
                              [disabled]="helpForm.invalid"
                              [ngClass]="helpForm.invalid ? 'bg-gray-200' : 'bg-primary'"
                              (click)="sendNotResolve()">{{ 'sendAsUnsolved' | transloco }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>