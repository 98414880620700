<div
  (click)="_snackBar.dismiss()"
  @fadeInRight
  [ngClass]="{
    'bg-red-800': data.type === 'warn','bg-green-600': data.type === 'success','bg-sky-600': data.type === 'info'
  }"
  class="text-sm rounded-lg shadow-lg m-2 text-white cursor-pointer"
  role="alert">
  <div *ngIf="data.message" class="flex flex-col sm:max-w-90">
    <div class="flex flex-row items-center">
      <mat-icon  class="text-white mx-2"
                [svgIcon]="data.type === 'success' ? 'mat_solid:check_circle' :
                 data.type === 'warn' ? 'mat_solid:cancel' :
                 'mat_solid:info'">
      </mat-icon>
      <div class="flex flex-col">
        <p class="font-bold text-lg px-2 pt-1">{{ data.title | transloco }}</p>
        <p class="p-1 font-semibold text-[0.9rem] break-all">{{ data.message | transloco }}</p>
      </div>
    </div>
    <mat-progress-bar *ngIf="data.type !== 'info'"
      [color]="data.type"
      mode="determinate"
      [value]="progress"
      class="px-2 mt-2 rounded-b-2xl"
    ></mat-progress-bar>
  </div>
</div>
