<div class="w-full h-auto">
    <div class="overflow-auto scroll-thin"
         style="max-height: calc(100vh - 200px);">
        <form [formGroup]="selectionForm">
            <div class="grid flex flex-col sm:grid-cols-3 gap-6 w-full mt-4 items-center p-5">
                <div class="col-span-1">
                    <mat-form-field class="w-full" subscriptSizing="dynamic">
                        <mat-label>{{ 'roles' | transloco }}</mat-label>
                        <mat-select formControlName="roles"
                                    [placeholder]="'chooseRoles' | transloco"
                                    (selectionChange)="selectionForm.get('company')?.disable(); selectionForm.get('userIds')?.disable()"
                                    multiple>
                            <mat-option *ngFor="let role of roles" [value]="role">{{ role | transloco }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-span-1">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>{{ 'companies' | transloco }}</mat-label>
                        <mat-select formControlName="company"
                                    (selectionChange)="selectionForm.get('roles')?.disable(); selectionForm.get('userIds')?.disable()"
                                    [placeholder]="'searchCompanyName' | transloco">
                            <div class="flex flex-row gap-2 p-2 mb-2">
                                <input [formControl]="filterCompanyControl"
                                       [placeholder]="'searchCompanyName' | transloco"
                                       class="bg-gray-200  border-2 border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
                                       type="text">
                            </div>
                            <mat-option class="text-md" *ngFor="let item of companies$ | async"
                                        [value]="item.id">{{ item.name }}
                            </mat-option>
                            <mat-option *ngIf="(companies$ | async)?.length<=0"
                                        [value]="'noCompanyFound'">{{ 'noCompanyFound' | transloco }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-span-1">
                    <mat-form-field class="w-full" subscriptSizing="dynamic">
                        <mat-label>{{ 'users' | transloco }}</mat-label>
                        <mat-select formControlName="userIds"
                                    (selectionChange)="selectionForm.get('roles')?.disable(); selectionForm.get('company')?.disable()"
                                    [placeholder]="'searchUserName' | transloco" multiple>
                            <div class="flex flex-row gap-2 p-2 mb-2">
                                <input
                                        [formControl]="filterUsersControl"
                                        [placeholder]="'searchUserName' | transloco"
                                        class="bg-gray-200  border-2 border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
                                        type="text">
                            </div>
                            <mat-option *ngFor="let user of users$ | async" [value]="user.id">
                                {{ user.name }}
                            </mat-option>
                            <mat-option *ngIf="(users$ | async)?.length<=0"
                                        [value]="'noUserFound'">{{ 'noUserFound' | transloco }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <form [formGroup]="notificationForm">
            <div class="mt-4 pl-5 flex justify-start">
                <input #fileInput type="file" (change)="onFileSelected($event)" class="hidden">
                <button mat-raised-button color="primary" (click)="fileInput.click()">
                    {{ 'uploadImage' | transloco }}
                </button>
            </div>
            <div class="flex flex-col w-full gap-6 p-5">
                <input type="text" class="bg-white border-2 p-3 rounded-xl" formControlName="title"
                       [placeholder]="'pleaseEnterTheTitle' | transloco">
                <textarea matInput class="p-3 overflow-y-auto resize rounded-xl bg-white"
                          [placeholder]="'pleaseEnterTheNotification' | transloco" rows="15"
                          formControlName="description"></textarea>
                <input type="text" class="bg-white border-2 p-3 rounded-xl" formControlName="link"
                       [placeholder]="'pleaseEnterTheLink' | transloco">
                <div class="flex justify-end">
                    <button mat-flat-button class="bg-primary text-white"
                            [disabled]="notificationForm.invalid || this.selectionForm.invalid"
                            [ngClass]="{'bg-primary': !notificationForm.invalid && !selectionForm.invalid}"
                            (click)="onSubmit()">{{ 'send' | transloco }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

