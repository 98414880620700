import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { SnackbarService } from './snackbar/snackbar.service';
import { GenericResponseModel } from '../model/generic.model';
import { PaginateResponse } from '../model/paginate.type';

class ParamsType {
}

@Injectable({
  providedIn: 'root',
})
export class HttpBaseService<T> {
  constructor(
    public http: HttpClient,
    public modelBaseUrl: string,
    public _snackBarService: SnackbarService,
  ) {
  }

  getPaginate<T>(urlSuffix: string, params?: ParamsType): Observable<GenericResponseModel<PaginateResponse<T>>> {
    return this.http.get<GenericResponseModel<PaginateResponse<T>>>(this.modelBaseUrl + urlSuffix, { params: this.createParams(params) }).pipe(
      catchError(error => {
        if (error && error.error && !error.error.success) {
          this._snackBarService.openSnackBar(
            { title: error.error.title? error.error.title : 'Error', message: error.error.message },
            'warn',
          );
        }
        return throwError(error);
      }),
    );
  }

  get<T>(urlSuffix: string, params?: ParamsType): Observable<GenericResponseModel<T>> {
    return this.http.get<GenericResponseModel<T>>(this.modelBaseUrl + (urlSuffix ? urlSuffix : ''), { params: this.createParams(params) }).pipe(
      catchError(error => {
        if (error && error.error && !error.error.success) {
          this._snackBarService.openSnackBar(
            { title: error.error.title? error.error.title : 'Error', message: error.error.message },
            'warn',
          );
        }
        return throwError(error);
      }),
    );
  }

  post<T>(body: T, urlSuffix?: string): Observable<GenericResponseModel<T>> {
    return this.http.post<GenericResponseModel<T>>(this.modelBaseUrl + (urlSuffix ? urlSuffix : ''), body).pipe(
      catchError(error => {
        if (error && error.error && !error.error.success) {
          this._snackBarService.openSnackBar(
            { title: error.error.title? error.error.title : 'Error', message: error.error.message },
            'warn',
          );
        }
        return throwError(error);
      }),
    );
  }

  delete<T>(id: number, urlSuffix?: string): Observable<GenericResponseModel<T>> {
    const url = this.modelBaseUrl + (urlSuffix ? urlSuffix : '') + (id ? id : '');
    return this.http.delete<GenericResponseModel<T>>(url).pipe(
      catchError(error => {
        if (error && error.error && !error.error.success) {
          this._snackBarService.openSnackBar(
            { title: error.error.title? error.error.title : 'Error', message: error.error.message },
            'warn',
          );
        }
        return throwError(error);
      }),
    );
  }

  update<T>(id: number, body: Partial<T>, urlSuffix?: string): Observable<GenericResponseModel<T>> {
    const url = this.modelBaseUrl + (urlSuffix ? urlSuffix : '') + (id ? id : '');
    return this.http.put<GenericResponseModel<T>>(url, body).pipe(
      catchError(error => {
        if (error && error.error && !error.error.success) {
          this._snackBarService.openSnackBar(
            { title: error.error.title? error.error.title : 'Error', message: error.error.message },
            'warn',
          );
        }
        return throwError(error);
      }),
    );
  }

  patch<T>(id: number, body: Partial<T>, urlSuffix?: string): Observable<GenericResponseModel<T>> {
    const url = this.modelBaseUrl + (urlSuffix ? urlSuffix : '') + (id ? id : '');
    return this.http.patch<GenericResponseModel<T>>(url, body).pipe(
      catchError(error => {
        if (error && error.error && !error.error.success) {
          this._snackBarService.openSnackBar(
            { title: error.error.title? error.error.title : 'Error', message: error.error.message },
            'warn',
          );
        }
        return throwError(error);
      }),
    );
  }

  createParams(params?: ParamsType) {
    let httpParams = new HttpParams();
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        httpParams = httpParams.append(key, value);
      });
    }
    return httpParams;
  }
}
