<div class="flex flex-col flex-auto min-w-0 space-y-8">
    <div class="w-full">
        <mat-card-content>
            <div  class="flex flex-col">
                <div class="flex flex-row items-center">
                  <mat-form-field appearance="outline" class="flex-1" [subscriptSizing]="'dynamic'">
                  <input matInput [formControl]="companyNameFilter"
                               placeholder="{{ 'searchCompanyName' | transloco }}">
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
    </div>
    <div class="relative z-[10]">
        <app-column-selector
                class="absolute right-0 top-2"
                [allColumns]="allColumns"
                [displayedColumns]="displayedColumns"></app-column-selector>
    </div>
    <div class="overflow-x-auto shadow-md bg-white">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="w-full shadow-lg">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap">{{ 'name' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="py-2 px-4 border-b whitespace-nowrap">{{ element.name}}
                </td>
            </ng-container>
            <ng-container matColumnDef="owner">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap"> {{ 'owner' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="py-2 px-4 border-b whitespace-nowrap capitalize">{{ element.owner }}
                </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap">{{ 'type' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="py-2 px-4 border-b whitespace-nowrap">{{ element.type | transloco }}
                </td>
            </ng-container>
            <ng-container matColumnDef="phoneNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap">{{ 'phoneNumber' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="py-2 px-4 border-b whitespace-nowrap">{{ element.phoneNumber }}
                </td>
            </ng-container>
            <ng-container matColumnDef="taxNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap">{{ 'taxNumber' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="py-2 px-4 border-b whitespace-nowrap">{{ element.taxNumber }}
                </td>
            </ng-container>
            <ng-container matColumnDef="ustId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap">{{ 'ustId' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="py-2 px-4 border-b whitespace-nowrap">{{ element.ustId }}
                </td>
            </ng-container>
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef class="py-2 px-4 border-b whitespace-nowrap"></th>
                <td mat-cell *matCellDef="let company" class="py-2 px-4 border-b whitespace-nowrap">
                   <div class="flex flex-row gap-2">
                     <mat-icon svgIcon="heroicons_outline:pencil-square" (click)="editCompany(company)"
                               class="cursor-pointer ease-in-out hover:text-green-700 rounded-full hover:shadow-3xl hover:shadow-gray-400">
                     </mat-icon>
                     <mat-icon *ngIf="company.blocked"  svgIcon="lock_open" (click)="unBlockCompany(company)"
                               class="cursor-pointer ease-in-out hover:text-green-700 rounded-full hover:shadow-3xl hover:shadow-gray-400">
                     </mat-icon>
                   </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-primary-50"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="hover:bg-gray-100 active:bg-gray-200"></tr>
        </table>
        <mat-paginator
                [length]="pagination?.length"
                [pageSize]="pagination?.pageSize || 20"
                [pageIndex]="pagination?.pageIndex || 0"
                [pageSizeOptions]="[5, 10, 20, 100]"
                [showFirstLastButtons]="true"
                (page)="pageChanged($event)">
        </mat-paginator>
    </div>
    <div class="sticky left-0 bottom-0 bg-gray-100 z-50 pb-4">
        <div class="bg-primary-50 border-y border-primary-200 w-full p-4 flex items-center justify-between">
            <ng-container>
                <strong>{{ 'total' | transloco }} {{ pagination?.length }} {{ 'Companies' | transloco }}</strong>
            </ng-container>
        </div>
    </div>
</div>
