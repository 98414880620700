import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Subject, takeUntil, tap } from 'rxjs';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatError, MatFormField, MatLabel, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { fuseAnimations } from '../../../../@fuse/animations';
import { CompanyModel } from '../../model/company.model';
import { TranslocoPipe } from '@ngneat/transloco';
import { CompanyService } from '../../services/company.service';
import { GenericResponseModel } from '../../model/generic.model';
import { Confirmable } from '../../decorators/confirmable.decorator';
import { FuseConfirmationService } from '../../../../@fuse/services/confirmation';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { AddressService } from '../../services/address.service';
import { AddressModel } from '../../model/address.model';
import {SnackbarService} from "../../services/snackbar/snackbar.service";


@Component({
  selector: 'app-company',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
  standalone: true,
  imports: [
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    NgFor,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    FormsModule,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatPrefix,
    MatSelect,
    MatSuffix,
    NgIf,
    ReactiveFormsModule,
    TranslocoPipe,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
  ],
  templateUrl: './company.component.html',
})
export class CompanyComponent implements OnInit, OnDestroy {
  @Input() companyId: number;
  @Output() newCompany: EventEmitter<CompanyModel> = new EventEmitter<CompanyModel>();
  companyForm: UntypedFormGroup;
  addressForm: UntypedFormGroup;
  company: CompanyModel;
  _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _companyService: CompanyService,
    private _addressService: AddressService,
    private cdr: ChangeDetectorRef,
    private confirmationService: FuseConfirmationService,
    private snackbarService: SnackbarService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this.initForm();
    if (this.companyId) {
      this._companyService.get<CompanyModel>(this.companyId.toString()).pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
        this.company = value.data;
        this.companyForm.patchValue(value.data);
        this.addressForm.patchValue(value.data.address)
      });
    }
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  initForm() {
    this.companyForm = this._formBuilder.group(
      {
        name: [this.company?.name, Validators.required],
        owner: [this.company?.owner, Validators.required],
        phoneNumber: [this.company?.phoneNumber],
        taxNumber: [this.company?.taxNumber, Validators.required],
        ustId: [this.company?.ustId, Validators.required],
        type: [this.company?.ustId, Validators.required],
        blocked: [this.company?.blocked, Validators.required]
      },
    );
    this.addressForm = this._formBuilder.group({
        postCode: [this.company?.address?.postCode, Validators.required],
        location: [this.company?.address?.location, Validators.required],
        city: [this.company?.address?.city, Validators.required],
        street: [this.company?.address?.street, Validators.required],
    })

    this.cdr.markForCheck();
  }

  @Confirmable({
    title: 'confirmation',
    message: 'doYouWantToUpdateCompanyInformation',
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation-circle',
      color: 'warn',
    },
  })
  onUpdate() {
    if(this.addressForm.touched || this.addressForm.dirty){
      this._addressService.update<AddressModel>(this.company.address.id, this.addressForm.value).pipe(takeUntil(this._unsubscribeAll), tap((value: GenericResponseModel<AddressModel>) => {
        this.snackbarService.openSnackBar(
            { title: 'success', message: 'updateProcessSuccessful' },
            'success',
        );
      })).subscribe();
    }
    this._companyService.update<CompanyModel>(this.company.id, this.companyForm.value).pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
      this.newCompany.emit(value.data);
      this.snackbarService.openSnackBar(
          { title: 'success', message: 'updateProcessSuccessful' },
          'success',
      );
    });
  }

  onCreate() {
    this._addressService.post(this.addressForm.value).pipe(
      takeUntil(this._unsubscribeAll),
      tap((value: GenericResponseModel<AddressModel>) => {
        this._companyService.post({ ...this.companyForm.value, address:{id:value.data.id} }).pipe(
          takeUntil(this._unsubscribeAll),
          tap((company: GenericResponseModel<CompanyModel>) => {
            this.newCompany.emit(company.data);
            this.company = company.data;
          }),
        ).subscribe();
      }),
    ).subscribe();

  }

  onReset() {
    this.companyForm.reset();
    this.companyForm.patchValue(this.company);
    this.addressForm.reset();
    this.addressForm.patchValue(this.company?.address)
  }

  onSubmit() {
    if (this.company?.id) {
      this.onUpdate();
    } else {
      this.onCreate();
    }
  }
}
