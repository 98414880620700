<div [ngClass]="selectedUser? 'h-[calc(100vh-160px)] overflow-y-auto' : '' " class="w-full px-4 pb-8">
        <!-- Form -->
        <form @fadeInLeft [formGroup]="accountForm">
            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                <!-- Username -->
                <div class="sm:col-span-4">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>{{ 'userName' | transloco}}</mat-label>
                        <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:user'"
                                matPrefix
                        ></mat-icon>
                        <input [formControlName]="'username'" matInput/>
                    </mat-form-field>
                </div>
            </div>

            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                <!-- Name -->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>{{ 'name' | transloco }}</mat-label>
                        <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:user'"
                                matPrefix
                        ></mat-icon>
                        <input [formControlName]="'name'" matInput/>
                    </mat-form-field>
                </div>
                <!--Appeal-->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" subscriptSizing="dynamic">
                        <mat-label>{{ 'chooseAnAppeal' | transloco }}</mat-label>
                        <mat-select formControlName="appeal">
                            <mat-option value="man">{{ 'man' | transloco }}</mat-option>
                            <mat-option value="women">{{ 'women' | transloco }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- Email -->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>{{ 'email' | transloco }}</mat-label>
                        <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:envelope'"
                                matPrefix
                        ></mat-icon>
                        <input [formControlName]="'email'" matInput/>
                    </mat-form-field>
                </div>
                <!-- Phone -->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>{{ 'phoneNumber' | transloco }}</mat-label>
                        <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:phone'"
                                matPrefix
                        ></mat-icon>
                        <input [formControlName]="'phoneNumber'" matInput/>
                    </mat-form-field>
                </div>
                <!-- Country -->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full">
                        <mat-label>{{ 'password' | transloco }}</mat-label>
                        <input
                                id="password"
                                matInput
                                type="password"
                                [formControlName]="'password'"
                                #passwordField
                        />
                        <button
                                mat-icon-button
                                type="button"
                                (click)="
              passwordField.type === 'password'
                ? (passwordField.type = 'text')
                : (passwordField.type = 'password')
            "
                                matSuffix
                        >
                            <mat-icon
                                    class="icon-size-5"
                                    *ngIf="passwordField.type === 'password'"
                                    [svgIcon]="'heroicons_solid:eye'"
                            ></mat-icon>
                            <mat-icon
                                    class="icon-size-5"
                                    *ngIf="passwordField.type === 'text'"
                                    [svgIcon]="'heroicons_solid:eye-slash'"
                            ></mat-icon>
                        </button>
                        <mat-error>
                            {{ 'passwordRequired' | transloco }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Language -->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full">
                        <mat-label>{{ 'passwordConfirm' | transloco }}</mat-label>
                        <input
                                id="passwordConfirm"
                                matInput
                                type="password"
                                [formControlName]="'passwordConfirm'"
                                #passwordConfirmField
                        />
                        <button
                                mat-icon-button
                                type="button"
                                (click)="
              passwordConfirmField.type === 'password'
                ? (passwordConfirmField.type = 'text')
                : (passwordConfirmField.type = 'password')
            "
                                matSuffix
                        >
                            <mat-icon
                                    class="icon-size-5"
                                    *ngIf="passwordConfirmField.type === 'password'"
                                    [svgIcon]="'heroicons_solid:eye'"
                            ></mat-icon>
                            <mat-icon
                                    class="icon-size-5"
                                    *ngIf="passwordConfirmField.type === 'text'"
                                    [svgIcon]="'heroicons_solid:eye-slash'"
                            ></mat-icon>
                        </button>
                        <mat-error>
                            {{ 'passwordConfirmRequired' | transloco }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- Actions -->
            <div class="flex items-center justify-end">
                <button mat-stroked-button type="button" (click)="onReset()">
                    {{ 'cancel' | transloco }}
                </button>
                <button
                        (click)="onSubmit()"
                        class="ml-4"
                        mat-flat-button
                        [disabled]="!accountForm.valid"
                        type="button"
                        [color]="'primary'"
                >
                    {{ 'save' | transloco }}
                </button>
            </div>
            <div [authView]="[ROLES.ROLE_ADMIN]" [hide]="true">
                <!-- Divider -->
                <div class="mt-11 mb-10 border-t"></div>
                <app-address [addressId]="user?.address?.id" (newAdress)="onUpdateAddress($event)"></app-address>
            </div>
            <div [authView]="[ROLES.ROLE_ADMIN]" [hide]="true">
                <!-- Divider -->
                <div class="mt-11 mb-10 border-t"></div>
                <app-company [companyId]="user?.company?.id" (newCompany)="onUpdateCompany($event)"></app-company>
            </div>
        </form>
    </div>
