export const environment = {
    production: true,
    apiUrl: 'https://api.leadsplace.de/api',
    notifications:'notifications',
    firebaseConfig : {
        apiKey: "AIzaSyCfUOWOzwpTNHB7LWfF92UGXxwRVHXmmWw",
        authDomain: "atlas-leadsplace.firebaseapp.com",
        databaseURL: "https://atlas-leadsplace-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "atlas-leadsplace",
        storageBucket: "atlas-leadsplace.appspot.com",
        messagingSenderId: "498171407328",
        appId: "1:498171407328:web:8b968257782007ac69d985"
    },
    defaultLang:"de"
};