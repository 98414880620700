import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {catchError, Observable, ReplaySubject, tap, throwError} from 'rxjs';
import {UserModel} from '../../shared/model/user.model';
import {endpointConfig} from '../../shared/config/endpoint.config';
import {GenericResponseModel} from '../../shared/model/generic.model';
import {PaginateResponse} from '../../shared/model/paginate.type';

@Injectable({providedIn: 'root'})
export class UserService {
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<UserModel> = new ReplaySubject<UserModel>(1);
    userApiUrl = endpointConfig.user;
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------


    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: UserModel) {
        // Store the value
        if (value) {
            localStorage.setItem('userRoles', JSON.stringify(value.roles));
        } else {
            localStorage.removeItem('userRoles');
        }
        this._user.next(value);
    }

    get user$(): Observable<UserModel> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<UserModel> {
        return this._httpClient.get<UserModel>('api/common/user').pipe(
            tap((user) => {
                this._user.next(user);
            }),
        );
    }

    getPaginate<T>(urlSuffix: string, params?: any): Observable<GenericResponseModel<PaginateResponse<T>>> {
        return this._httpClient.get<GenericResponseModel<PaginateResponse<T>>>(this.userApiUrl + urlSuffix,
            {params: params}).pipe(
            catchError(error => {
                if (error && error.error && !error.error.success) {
                    console.error(error.error.message);
                }
                return throwError(error);
            }),
        );
    }

    delete<T>(id: number, urlSuffix?: string): Observable<GenericResponseModel<T>> {
        const url = this.userApiUrl + (urlSuffix ? urlSuffix : '') + (id ? id : '');
        return this._httpClient.delete<GenericResponseModel<T>>(url).pipe(
            catchError(error => {
                if (error && error.error && !error.error.success) {
                    console.error(error.error.message);
                }
                return throwError(error);
            }),
        );
    }

    post<T>(body: T, urlSuffix?: string): Observable<GenericResponseModel<T>> {
        return this._httpClient.post<GenericResponseModel<T>>(this.userApiUrl + (urlSuffix ? urlSuffix : ''), body).pipe(
            catchError(error => {
                if (error && error.error && !error.error.success) {
                    console.error(error.error.message);
                }
                return throwError(error);
            }),
        );
    }

    patch<T>(id: number, data: Partial<T>, urlSuffix?: string): Observable<GenericResponseModel<T>> {
        const url = this.userApiUrl + (urlSuffix ? urlSuffix : '') + (id ? id : '');
        return this._httpClient.patch<GenericResponseModel<T>>(url, data).pipe(
            catchError(error => {
                if (error && error.error && !error.error.success) {
                    console.error(error.error.message);
                }
                return throwError(error);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(userId: number, user: UserModel, updateLogedUser = true): Observable<GenericResponseModel<UserModel>> {
        return this._httpClient.put<GenericResponseModel<UserModel>>(this.userApiUrl + userId, user).pipe(
            tap((response) => {
                updateLogedUser ? this._user.next(new UserModel(response.data)) : '';
            }),
        );
    }
}
