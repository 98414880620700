import {Component, Input, OnInit} from '@angular/core';
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {AsyncPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {TranslocoPipe} from "@ngneat/transloco";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormControl, Validators} from "@angular/forms";
import {ROLES} from "../../../../shared/model/user.roles.enum";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";
import {CompanyModel} from "../../../../shared/model/company.model";
import {UserModel} from "../../../../shared/model/user.model";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {CompanyService} from "../../../../shared/services/company.service";
import {GenericResponseModel} from "../../../../shared/model/generic.model";
import {PaginateResponse} from "../../../../shared/model/paginate.type";
import {UserService} from "../../../../core/user/user.service";
import {NotificationsService} from "../../../../layout/common/notifications/notifications.service";
import {SnackbarService} from "../../../../shared/services/snackbar/snackbar.service";

@Component({
    selector: 'app-send-notification',
    standalone: true,
    imports: [
        MatFormField,
        MatLabel,
        MatOption,
        MatSelect,
        NgForOf,
        TranslocoPipe,
        FormsModule,
        ReactiveFormsModule,
        AsyncPipe,
        NgIf,
        MatInput,
        MatButton,
        NgClass
    ],
    templateUrl: './send-notification.component.html',
})
export class SendNotificationComponent implements OnInit {
    @Input() refreshSendNotifications$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    roles: string[] = [];
    file: File;
    companies$: BehaviorSubject<CompanyModel[]> = new BehaviorSubject<CompanyModel[]>([]);
    users$: BehaviorSubject<UserModel[]> = new BehaviorSubject<UserModel[]>([]);
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    filterCompanyControl: UntypedFormControl = new UntypedFormControl(null);
    filterUsersControl: UntypedFormControl = new UntypedFormControl(null);
    notificationForm: FormGroup;
    selectionForm: FormGroup;

    constructor(private fb: FormBuilder, private companyService: CompanyService, private userService: UserService, private notificationsService: NotificationsService, private snackbarService: SnackbarService) {
        this.roles = Object.values(ROLES);
        this.selectionForm = this.fb.group({
            roles: [[]],
            company: [''],
            userIds: [[]],
        })
        this.notificationForm = this.fb.group({
            title: ['', Validators.required],
            description: [''],
            type: ['NOTIFICATION'],
            useRouter: ['false'],
            link: [''],
            icon: ['heroicons_solid:check-circle'],
        });
    }

    ngOnInit() {
        this.refreshSendNotifications$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            if (value) {
                this.loadUser();
                this.loadCompany();
                this.refreshSendNotifications$.next(false);
            }
        });
    }

    loadUser(query?: string) {
        this.userService.getPaginate<GenericResponseModel<UserModel>>('get-all', {
            ...(query ? {filter: query} : {filter: ''}),
        }).subscribe(value => {
            this.users$.next(value.data.content.map(v => new UserModel(v)));
        });
    }

    loadCompany(query?: string) {
        this.companyService
            .get<GenericResponseModel<PaginateResponse<CompanyModel>>>(`filter`, {filter: query || ''})
            .subscribe({
                next: (response) => {
                    this.companies$.next(response.data['content'].map(x => new CompanyModel(x)));
                },
            });
    }

    onFileSelected(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            this.file = input.files[0];
        }
    }

    onSubmit() {
        if (this.notificationForm.valid && this.selectionForm.valid) {
            const formData = new FormData();
            if (this.file) {
                formData.append('image', this.file);
            }
            let notification = this.notificationForm.value
            let selectionForm = this.selectionForm.value

            if (!this.selectionForm.controls['company'].value) {
                selectionForm['roles'] ? (notification.roles = selectionForm['roles']) : '';
                selectionForm['userIds'] ? (notification.userIds = selectionForm['userIds']) : '';
                selectionForm['company'] ? (notification.company = selectionForm['company']) : '';
            }
            if (!this.notificationForm.controls['link'].value) {
                notification.useRouter = 'true';
            }
            formData.append('notification', JSON.stringify(notification));

            const urlSuffix = this.selectionForm.controls['company'].value ? `company/${this.selectionForm.controls['company'].value}` : '';
            this.notificationsService.post<any>(formData, urlSuffix)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe({
                    next: (response) => {
                        this.snackbarService.openSnackBar({title: 'success', message: 'notificationSentSuccessfully'},
                            'success',
                        );
                        this.notificationForm.reset();
                        this.selectionForm.reset();
                        this.enableAllControls()
                        this.notificationForm.controls['type'].patchValue('NOTIFICATION')
                    },
                    error: (error) => {
                        console.error('notificationSendError', error);
                    }
                });
        }
    }

    enableAllControls() {
        Object.keys(this.selectionForm.controls).forEach(control => {
            this.selectionForm.get(control).enable();
        });
    }
}
