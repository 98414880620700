<div class="flex flex-col flex-auto min-w-0 space-y-8">
    <div class="flex flex-col flex-auto pt-3">
        <div class="w-full">
            <form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="overflow-auto p-5 scroll-thin"
                  style="max-height: calc(100vh - 200px);">
                <div class="grid sm:grid-cols-4 gap-6 w-full mt-1">
                    <div class="ml-2 lg:ml-0 text-3xl font-bold tracking-tight leading-none capitalize">{{ 'profile' | transloco }}</div>
                    <div class="sm:col-span-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>{{ 'userName' | transloco }}</mat-label>
                            <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:user'"
                                    matPrefix
                            ></mat-icon>
                            <input [formControlName]="'username'" matInput/>
                            <mat-error *ngIf="userForm.get('username').invalid">{{ 'usernameRequired' | transloco }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                    <div class="sm:col-span-2">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>{{ 'name' | transloco }}</mat-label>
                            <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:user'"
                                    matPrefix
                            ></mat-icon>
                            <input [formControlName]="'name'" matInput/>
                        </mat-form-field>
                    </div>
                    <div class="sm:col-span-2">
                        <mat-form-field class="w-full" subscriptSizing="dynamic">
                            <mat-label>{{ 'chooseAnAppeal' | transloco }}</mat-label>
                            <mat-select [formControlName]="'appeal'">
                                <mat-option value="man">{{ 'man' | transloco }}</mat-option>
                                <mat-option value="women">{{ 'women' | transloco }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="sm:col-span-2">
                        <mat-form-field class="w-full" subscriptSizing="dynamic">
                            <mat-label>{{ 'roles' | transloco }}</mat-label>
                            <mat-select (selectionChange)="setRole($event)">
                                <mat-option *ngFor="let role of roles" [value]="role">{{ role | transloco }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="sm:col-span-2">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>{{ 'avatar' | transloco }}</mat-label>
                            <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:user'"
                                    matPrefix
                            ></mat-icon>
                            <input [formControlName]="'avatar'" matInput/>
                        </mat-form-field>
                    </div>
                    <div class="sm:col-span-2">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>{{ 'email' | transloco }}</mat-label>
                            <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:envelope'"
                                    matPrefix
                            ></mat-icon>
                            <input [formControlName]="'email'" matInput/>
                        </mat-form-field>
                    </div>

                    <div class="sm:col-span-2">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>{{ 'phoneNumber' | transloco }}</mat-label>
                            <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:phone'"
                                    matPrefix
                            ></mat-icon>
                            <input [formControlName]="'phoneNumber'" matInput/>
                        </mat-form-field>
                    </div>
                    <div class="sm:col-span-2">
                        <mat-form-field class="w-full">
                            <mat-label>{{ 'password' | transloco }}</mat-label>
                            <input
                                    id="password"
                                    matInput
                                    type="password"
                                    [formControlName]="'password'"
                                    #passwordField
                            />
                            <button
                                    mat-icon-button
                                    type="button"
                                    (click)="passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')"
                                    matSuffix
                            >
                                <mat-icon
                                        class="icon-size-5"
                                        *ngIf="passwordField.type === 'password'"
                                        [svgIcon]="'heroicons_solid:eye'"
                                ></mat-icon>
                                <mat-icon
                                        class="icon-size-5"
                                        *ngIf="passwordField.type === 'text'"
                                        [svgIcon]="'heroicons_solid:eye-slash'"
                                ></mat-icon>
                            </button>
                            <mat-error>
                                {{ 'passwordRequired' | transloco }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="mt-11 mb-10 border-t"></div>
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>{{ 'addAddress' | transloco }}</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div [formGroupName]="'address'" class="pb-10">
                            <div class="ml-2 lg:ml-0 text-3xl font-bold tracking-tight leading-none capitalize">{{ 'address' | transloco }}</div>
                            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>{{ 'postCode' | transloco }}</mat-label>
                                        <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:envelope'"
                                                matPrefix
                                        ></mat-icon>
                                        <input [formControlName]="'postCode'" matInput/>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>{{ 'location' | transloco }}</mat-label>
                                        <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:paper-airplane'"
                                                matPrefix
                                        ></mat-icon>
                                        <input [formControlName]="'location'" matInput/>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>{{ 'city' | transloco }}</mat-label>
                                        <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:home'"
                                                matPrefix
                                        ></mat-icon>
                                        <input [formControlName]="'city'" matInput/>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>{{ 'street' | transloco }}</mat-label>
                                        <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:map'"
                                                matPrefix
                                        ></mat-icon>
                                        <input [formControlName]="'street'" matInput/>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>{{ 'addCompany' | transloco }}</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div [formGroupName]="'company'" class="pb-10">
                            <div class="ml-2 mb-6 lg:ml-0 text-3xl font-bold tracking-tight leading-none capitalize">{{ 'company' | transloco }}</div>
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-select (selectionChange)="selectCompany($event)"
                                            [placeholder]="'searchCompanyName' | transloco">
                                    <div class="flex flex-row gap-2 p-2 mb-2">
                                        <input
                                                [placeholder]="'searchCompanyName' | transloco"
                                                [formControl]="filterCompanyControl"
                                                class="bg-gray-200  border-2 border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
                                                type="text">
                                    </div>
                                    <mat-option class="text-md" *ngFor="let item of companies$ | async"
                                                [value]="item.id">{{ item.name }}
                                    </mat-option>
                                    <mat-option *ngIf="companies$.value.length<=0"
                                                [value]="'noCompanyFound'">{{ 'noCompanyFound' | transloco }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>{{ 'companyName' | transloco }}</mat-label>
                                        <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:building-office'"
                                                matPrefix
                                        ></mat-icon>
                                        <input [formControlName]="'name'" matInput/>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" subscriptSizing="dynamic">
                                        <mat-label>{{ 'chooseType' | transloco }}</mat-label>
                                        <mat-select [formControlName]="'type'">
                                            <mat-option value="customer">{{ 'customer' | transloco }}</mat-option>
                                            <mat-option value="seller">{{ 'seller' | transloco }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>{{ 'companyOwner' | transloco }}</mat-label>
                                        <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'mat_solid:real_estate_agent'"
                                                matPrefix
                                        ></mat-icon>
                                        <input [formControlName]="'owner'" matInput/>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>{{ 'taxNumber' | transloco }}</mat-label>
                                        <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:briefcase'"
                                                matPrefix
                                        ></mat-icon>
                                        <input [formControlName]="'taxNumber'" matInput/>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>{{ 'ustId' | transloco }}</mat-label>
                                        <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:identification'"
                                                matPrefix
                                        ></mat-icon>
                                        <input [formControlName]="'ustId'" matInput/>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>{{ 'phoneNumber' | transloco }}</mat-label>
                                        <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:phone'"
                                                matPrefix
                                        ></mat-icon>
                                        <input [formControlName]="'phoneNumber'" matInput/>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="my-10 border-t"></div>
                            <div class="ml-2 lg:ml-0 text-3xl font-bold tracking-tight leading-none capitalize">{{ 'companyAddress' | transloco }}</div>
                            <div [formGroupName]="'address'" class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>{{ 'postCode' | transloco }}</mat-label>
                                        <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:envelope'"
                                                matPrefix
                                        ></mat-icon>
                                        <input [formControlName]="'postCode'" matInput/>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>{{ 'location' | transloco }}</mat-label>
                                        <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:paper-airplane'"
                                                matPrefix
                                        ></mat-icon>
                                        <input [formControlName]="'location'" matInput/>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>{{ 'city' | transloco }}</mat-label>
                                        <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:home'"
                                                matPrefix
                                        ></mat-icon>
                                        <input [formControlName]="'city'" matInput/>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-label>{{ 'street' | transloco }}</mat-label>
                                        <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:map'"
                                                matPrefix
                                        ></mat-icon>
                                        <input [formControlName]="'street'" matInput/>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <div class="flex justify-end pt-10">
                    <button mat-flat-button class="bg-primary text-white" type="submit">{{ 'save' | transloco }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
